import i18next from "i18next";
import { AccountContextProvider } from "../contexts/AccountContext";
import { isNull } from "lodash";

export default class GigyaKit {
  constructor() {
    //this.history = useHistory();
    this.TENANTS = {
      KEYRUS: {
        defaultScreenSetName: "KeyrusTest-Demo-RegistrationLogin",
        key: "3_q5iWkbbHLyicAhDIX4jdPX9GWlVyOBIZi3uST49fBCA1YObwc91DmEmU_p4hdjoD",
        domain: "test.com",
        description: "Test",
      },
      WILLANDSKILL: {
        // THIS ONE IS USED BY ADRIAN
        defaultScreenSetName: "Default-RegistrationLogin",
        key: "3_9U9PrbmhL7UQ9OisUHX2U4xx-_YrKU2h1zZOs0GrmhJt6Uu8PsVpStFE-H0qjvlE",
        domain: "willandskill.eu",
        description: "ESTA Will and Skill Development environment",
      },
      ESTA_DEV: {
        defaultScreenSetName: "Default-RegistrationLogin",
        key: "3_RTG5sNzpSmjs3ov25XK6bEDHVmRxZ3XGPNJZlBZrykWDxmu8rseK6dJ1hnl_OETV",
        domain: "reactamp.d3btw5ge387sib.amplifyapp.com",
        description: "React Amplify Test App in EU",
      },
      MOTIVA: {
        defaultScreenSetName: "Default-RegistrationLogin",
        key: "3_M5I7KkjoCJUp-hihlkCTKfKpQV-6FrOgHDbacxMZeYnH98ocGdDuOFvByr7Q4g4U",
        domain: "motiva.health",
        description: "Motiva Health webpage",
      },
    };
    // this.API_KEY = "3_9U9PrbmhL7UQ9OisUHX2U4xx-_YrKU2h1zZOs0GrmhJt6Uu8PsVpStFE-H0qjvlE"; // Will and Skill Test
    // this.API_KEY = '3_q5iWkbbHLyicAhDIX4jdPX9GWlVyOBIZi3uST49fBCA1YObwc91DmEmU_p4hdjoD' // KEYRUS TEST KEY
    // this.API_KEY = "3_UTcWh71Xv1cf5UKfsS4OMZ6tGE10fUF0Dw_8c_ufhKC_apXPIJOP6NS4wpJwWgyt" // ESTA API KEY
    // this.API_KEY = "3_RTG5sNzpSmjs3ov25XK6bEDHVmRxZ3XGPNJZlBZrykWDxmu8rseK6dJ1hnl_OETV" // React Amplify Test App in EU - WORKS WITH ESTA VALIDATE ENDPOINT

    const TENANT = process.env.REACT_APP_GIGYA_TENANT;
    this.TENANT_DATA = this.TENANTS[TENANT];

    this.API_KEY = this.TENANT_DATA.key;
    this.defaultScreenSetName = this.TENANT_DATA.defaultScreenSetName;
    this.profileUpdateScreenSetName = "Default-ProfileUpdate";
    this.domain = this.TENANT_DATA.domain;
    this.description = this.TENANT_DATA.description;

    this.ACCOUNT_INFO_KEY = "GIGYA_ACCOUNT_INFO";
  }

  get_javascript_url = () => {
    return `https://cdns.eu1.gigya.com/js/gigya.js?apikey=${this.API_KEY}`;
  };

  redirect = () => {
    AccountContextProvider.updateAccountInfo();
  };

  /*showScreenSetLogin = (
    screenSetName,
    screenId,
    redirectURL,
    callback,
    redirectMethod
  ) => {
    let lang =
      i18next.language ||
      window.chosenLanguage ||
      window.navigator.userLanguage ||
      window.navigator.language;
    lang = lang.substring(0, 2);
    if (lang === "nn") {
      lang = "no";
    }
    return window.gigya.accounts.showScreenSet({
      screenSet: screenSetName,
      startScreen: screenId,
      gigyaContainerId: "motiva-gigya-container",
      lang: lang,
      onAfterSubmit: (event) => {
        if (event.response.errorCode === 0 &&
          event.screen !== 'gigya-complete-registration-screen'
        ) {
          window.history.go(0);
        }
      },
    });
  };*/

  //  onAfterSubmit: (event) => {
  //         if (event.response.errorCode === 0 &&
  //           event.screen !== 'gigya-complete-registration-screen'
  //         ) {
  //           this.redirect();
  //         }
  //       },

  showScreenSet = (
    screenSetName,
    screenId,
    redirectURL,
    callback,
    redirectMethod,
    events = {},
  ) => {
    let lang =
      i18next.language ||
      window.chosenLanguage ||
      window.navigator.userLanguage ||
      window.navigator.language;
    lang = lang.substring(0, 2);
    if (lang === "nn") {
      lang = "no";
    }
    return window.gigya.accounts.showScreenSet({
      screenSet: screenSetName,
      startScreen: screenId,
      redirectURL: redirectURL,
      redirectMethod: redirectMethod,
      lang: lang,
      callback: callback,
      onBeforeScreenLoad: (event) => {
        const { profile } = event;
        const hasCountry = !!profile.country;
        const hasBirthDate =
          profile.birthYear && profile.birthMonth && profile.birthDay;
        if (
          event.nextScreen === "gigya-complete-registration-screen" &&
          (!hasCountry || !hasBirthDate)
        ) {
          return {
            nextScreen: "registration-completion-social-providers-screen",
          };
        }
        return true;
      },
      onAfterScreenLoad: (event) => {
        const screenSets = [
          "gigya-login-screen",
          "gigya-register-screen",
          "registration-completion-social-providers-screen",
          "gigya-complete-registration-screen",
        ];
        const identifiers = {
          "gigya-login-screen": "patient-form-submit_login",
          "gigya-register-screen": "patient-form-submit_step_1",
          "registration-completion-social-providers-screen":
            "patient-form-submit_step_2",
          "gigya-complete-registration-screen": "patient-form-submit_step_3",
        };

        if (screenSets.includes(event.currentScreen)) {
          const botton = document.querySelector(".gigya-input-submit");
          botton.id = identifiers[event.currentScreen];
        }

        const url = new URL(window.location.href.replace(/#\/+/g, ""));
        const getParameter = url.searchParams.get("hideSocialMedia");
        const socialLogin = document.querySelector(".with-social-login");

        if (!isNull(getParameter)) {
          sessionStorage.setItem(
            "isSocialMediaHide",
            url.searchParams.get("hideSocialMedia"),
          );
        }

        const isSocialMediaHide = sessionStorage.getItem("isSocialMediaHide");

        if (socialLogin) {
          socialLogin.classList.remove("hide-social-login");
          if (isSocialMediaHide === "true") {
            socialLogin.classList.add("hide-social-login");
          }
        }
      },
      onAfterSubmit: (event) => {},
      ...events,
    });
  };

  hideScreenSet = (
    screenSet,
    containerID = undefined,
    callback = undefined,
  ) => {
    return window.gigya.accounts.hideScreenSet({
      screenSet,
      containerID,
      callback,
    });
  };

  initRegistration = () => {
    return new Promise(function (resolve, reject) {
      window.gigya.accounts.initRegistration({ callback: resolve });
    });
  };

  showProfileUpdateScreenSet = (redirectURL) => {
    return this.showScreenSet(
      "Default-ProfileUpdate",
      "gigya-update-profile-screen",
      redirectURL,
    );
  };

  showResetPasswordScreenSet = (redirectURL) => {
    return this.showScreenSet(
      this.defaultScreenSetName,
      "gigya-reset-password-screen",
      redirectURL,
    );
  };

  showLoginScreenSet = (redirectURL, screenSet, events = {}) => {
    return new Promise((resolve, reject) => {
      this.showScreenSet(
        screenSet,
        "gigya-login-screen",
        redirectURL,
        resolve,
        "get",
        events,
      );
    });
  };

  hideLoginScreenSet(screenSet) {
    return new Promise((resolve, reject) => {
      this.hideScreenSet(screenSet, undefined, resolve);
    });
  }

  showRegisterScreenSet = (redirectURL) => {
    return this.showScreenSet(
      this.defaultScreenSetName,
      "gigya-register-screen",
      redirectURL,
    );
  };

  showDoctorRegisterScreenSet = (redirectURL) => {
    return this.showScreenSet(
      "SURGEON_REG",
      "gigya-register-screen",
      redirectURL,
    );
  };
  // CONSENT
  // Screen-Set ID: Default-RegistrationLogin,
  // https://help.sap.com/viewer/8b8d6fffe113457094a17701f63e3d6a/GIGYA/en-US
  showRegisterConsentScreenSet = () => {
    return new Promise((resolve) => {
      window.gigya.accounts.showScreenSet({
        screenSet: this.defaultScreenSetName,
        startScreen: "CONSENT",
        onAfterSubmit: resolve,
      });
    });
  };

  showRegistrationCompletionScreenSet = (redirectURL) => {
    return this.showScreenSet(
      this.defaultScreenSetName,
      "gigya-complete-registration-screen",
      redirectURL,
    );
  };

  requestPasswordConfirmation = () => {
    return new Promise((resolve, reject) => {
      return this.showScreenSet(
        "SURGEON_REG",
        "gigya-password-confirmation-screen",
        undefined,
        undefined,
        undefined,
        {
          onAfterSubmit: (event) => {
            if (event.response.errorCode === 0) {
              resolve();
            }
          },
          onHide: () => {
            reject("The password was not given");
          },
        },
      );
    });
  };

  getJWT = () => {
    return new Promise(function (resolve, reject) {
      window.gigya.accounts.getJWT({ callback: resolve });
    });
  };

  async getAccountInfo() {
    this.deleteStoredAccountInfo();
    const tempGetAccountInfo = new Promise(function (resolve, reject) {
      window.gigya.accounts.getAccountInfo({ callback: resolve });
    });
    tempGetAccountInfo.then((data) => {
      this.setStoredAccountInfo(data);
    });
    return tempGetAccountInfo;
  }

  setAccountInfo = (payload) => {
    return new Promise(function (resolve, reject) {
      payload.callback = resolve;
      window.gigya.accounts.setAccountInfo(payload);
    });
  };

  logout = () => {
    return new Promise(function (resolve, reject) {
      try {
        window.gigya.accounts.logout({ callback: resolve });
      } catch (e) {
        reject(e);
      }
    });
  };

  setStoredAccountInfo = (accountInfo) => {
    return sessionStorage.setItem(
      this.ACCOUNT_INFO_KEY,
      JSON.stringify(accountInfo),
    );
  };

  deleteStoredAccountInfo = () => {
    return sessionStorage.setItem(this.ACCOUNT_INFO_KEY, null);
  };

  getStoredAccountInfo = () => {
    return JSON.parse(sessionStorage.getItem(this.ACCOUNT_INFO_KEY));
  };

  getStoredAccountEmail = () => {
    const accountInfo = this.getStoredAccountInfo();
    if (accountInfo && accountInfo.profile) {
      return accountInfo.profile.email;
    }
    return null;
  };
}
