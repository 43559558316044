import React, { useContext } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Card } from "../../common/Card";
import { useTranslation } from "react-i18next";
import { isNull, isUndefined, omit } from "lodash";
import { Pagination } from "../../common/Pagination";
import DateRenderer from "../DateRenderer";
import { UserContext } from "../../../contexts/UserContext";
import { useSelector } from "react-redux";
import { isPast } from "date-fns";

const ImplantInformationComponent = (props) => {
  const { value, title, twoRow = true } = props;

  return (
    <Box mt={0.6}>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <Typography variant="body2" fontWeight={550}>
            {title}:
          </Typography>
        </Grid>
        <Grid item xs={6}>
          {value}
        </Grid>
      </Grid>
    </Box>
  );
};

export const ImplantInformationSummary = (prop) => {
  const {
    breastData,
    setPage,
    page,
    handleSetInformationExplant,
    statusSurgery,
  } = prop;
  const { me } = useContext(UserContext);
  const { t } = useTranslation();
  const featureFlag = useSelector((state) => state.featureFlagSlice.features);
  let isFeatureStatus = () =>
    featureFlag &&
    featureFlag.filter((feat) => feat.feature === "us_surgeon_dt")[0].status;
  let accountCountryUS =
    me?.userCountry === "US" && featureFlag && isFeatureStatus() === 1;

  const handlePagination = (event, value) => {
    setPage(value);
  };

  const formatValue = (value, key, text) => {
    switch (key) {
      case "is_active": {
        return value === 1 ? "Active" : "Inactive";
      }
      case "date":
      case "startDate":
      case "endDate": {
        return <DateRenderer date={value} label={text} />;
      }
      case "placement_id":
        return t(`API.ImplantPlacement-${value}`);
      case "indication_id":
        return t(`API.SurgeryIndication-${value}`);
      case "incision_site_id":
        return t(`API.IncisionType-${value}`);
      default:
        return value;
    }
  };

  const procedureProperties = {
    procedureType: t("PreRegistration.ProcedureIndicationLabel"),
    date: t("Date.Title"),
    surgeon: t("Global.Surgeon"),
    is_active: t("Patient-List-Card.Status"),
    incision_site_id: t("Patient-List-Implant-Card.IncisionSite"),
    indication_id: t("Patient-List-Card.Indication"),
    placement_id: t("Patient-List-Implant-Card.implantPlacement"),
  };

  const procedureTools = {
    injector: t("Implant-Registration.Injector"),
    balloon: t("Implant-Registration.Balloon"),
  };
  const implantProperties = {
    sides: t("PreRegistration.ImplantSideSelectionLabel"),
    implantType: t("Patient-List-Implant-Card.Type"),
    profile: t("Patient-List-Implant-Card.Profile"),
    volume: t("Patient-List-Implant-Card.Volume"),
    serialNumber: t("Patient-List-Implant-Card.SerialNumber"),
    esn: t("Global.ESN"),
    base: t("Patient-List-Implant-Card.Base"),
    projection: t("Patient-List-Implant-Card.Projection"),
    reference: t("Patient-List-Implant-Card.Reference"),
    surface: t("Patient-List-Implant-Card.Surface"),
    family: t("Patient-List-Implant-Card.Family"),
  };

  const warrantyProperties = {
    status: t("Patient-List-Warranty-Info.Status"),
    program: t("Global.Program"),
    currentProgram: `${t("Global.Current")} ${t("Global.Program")}`,
    coverage: t("Patient-List-Warranty-Info.Coverage"),
    startDate: t("Global.startDate"),
    endDate: t("Patient-List-Warranty-Info.Valid-Until"),
    motivaProgram: "Motiva Program",
    warrantyEligible: t("WarrantyEligible"),
  };

  const selectedProgram = (surgery, elegible) => {
    const program = surgery.warranty?.program;
    const programSelected = `${surgery.warranty?.program} ${warrantyProperties["motivaProgram"]} ($${surgery.warranty?.coverage} ${warrantyProperties["coverage"]})`;

    if (program === "5Y" || program === "2Y") {
      if (
        surgery?.warranty?.status === "inactive" &&
        isNull(surgery?.warranty?.orderId) &&
        !surgery?.warranty?.endDate
      ) {
        return t("Warranty.Program.0");
      }
      if (
        surgery?.warranty?.orderId !== 0 &&
        surgery?.warranty?.endDate &&
        !isNull(surgery?.warranty?.endDate)
      ) {
        return programSelected;
      }
    }

    if (program === "5Y") {
      if (
        (surgery?.warranty?.orderId === 0 ||
          isNull(surgery?.warranty?.orderId)) &&
        surgery?.warranty?.endDate
      ) {
        return elegible ? programSelected : t("Warranty.Program.1");
      }
    }

    if (program === "2Y") {
      if (surgery?.warranty?.orderId === 0) {
        return elegible ? programSelected : t("Warranty.Program.1");
      }
      if (isNull(surgery?.warranty?.orderId)) {
        return elegible ? programSelected : t("Warranty.Program.0");
      }
    }

    if (program === "Mia®") {
      return "Mia®";
    }
  };

  const validUntil = (surgery) => {
    if (isPast(new Date(surgery?.warranty?.endDate))) {
      return formatValue(
        surgery?.warranty?.endDate,
        "endDate",
        t("Warranty.NotActive"),
      );
    }

    if (surgery?.warranty?.status === "inactive") {
      return formatValue(
        surgery?.warranty?.endDate,
        "endDate",
        t("Device.Explanted"),
      );
    }

    return formatValue(surgery?.warranty?.endDate, "endDate");
  };

  const separateWarrantyCard = (surgery) => {
    return (
      surgery.devices?.implants?.left?.warranty?.program !==
        surgery.devices?.implants?.right?.warranty?.program ||
      surgery.devices?.implants?.left?.warranty?.orderId !==
        surgery.devices?.implants?.right?.warranty?.orderId ||
      surgery.devices?.implants?.left?.warranty?.status !==
        surgery.devices?.implants?.right?.warranty?.status ||
      surgery.devices?.implants?.left?.warranty?.startDate !==
        surgery.devices?.implants?.right?.warranty?.startDate
    );
  };

  function renderWarrantyEligibilityRow(surgery, side) {
    const warranty = surgery?.devices?.implants[side]?.warranty ?? null;

    if (isNull(warranty)) return <></>;

    const noWarrantyOrder = warranty.orderId === 0 || isNull(warranty?.orderId);

    if (noWarrantyOrder && warranty.status === "active") {
      const eligibleProgram = selectedProgram(
        surgery.devices?.implants[side],
        true,
      );

      if (eligibleProgram) {
        return (
          <ImplantInformationComponent
            value={eligibleProgram}
            title={warrantyProperties["warrantyEligible"] || "N/A"}
          />
        );
      }
    }

    return <></>;
  }

  function renderWarrantyProgramName(surgery, side) {
    const implants = surgery.devices?.implants ?? null;
    const implantSide = surgery.devices?.implants[side] ?? null;

    if (isNull(implants) || isNull(implantSide)) return <></>;

    const programName =
      implants?.type === "g"
        ? t("Warranty.Program.0")
        : selectedProgram(implants[side]);

    if (programName)
      return (
        <ImplantInformationComponent
          value={programName}
          title={warrantyProperties["currentProgram"] || "N/A"}
        />
      );
  }

  return (
    <>
      {breastData?.surgeries.map((surgery, idx) => (
        <Box
          key={idx}
          sx={{
            height: "580px",
            overflowY: "scroll",
            pb: 3,
          }}
        >
          <Grid container spacing={2} mt={1}>
            {(typeof surgery.procedureDetails?.left !== "undefined" ||
              typeof surgery.procedureDetails?.date !== "undefined") && (
              <Grid
                item
                xs={12}
                md={
                  surgery.procedureDetails?.left &&
                  surgery.procedureDetails?.right
                    ? 6
                    : 12
                }
                order={{ xs: 1, sm: 1, md: 1 }}
              >
                <Card>
                  <Box>
                    <Box mb={1}>
                      <Typography variant="h6" color="#801973">
                        {t("Procedure-Information.Title")}
                      </Typography>
                    </Box>
                    {Object.entries(
                      surgery.procedureDetails?.left ||
                        surgery.procedureDetails,
                    )
                      .filter(
                        ([key, value]) => key !== "tools" && !isNull(value),
                      )
                      .map(([key, value], idx) => (
                        <ImplantInformationComponent
                          key={idx}
                          value={formatValue(value, key)}
                          title={
                            (!isUndefined(key) && procedureProperties[key]) ||
                            "N/A"
                          }
                        />
                      ))}
                  </Box>

                  {(typeof surgery.procedureDetails?.left?.tools !==
                    "undefined" ||
                    typeof surgery.procedureDetails?.tools !== "undefined") && (
                    <Box mt={1}>
                      <Box mb={1}>
                        <Typography variant="h6" color="#801973">
                          {t("Procedure.Tools.Title")}
                        </Typography>
                      </Box>
                      {Object.entries(
                        surgery.procedureDetails?.left?.tools ||
                          surgery.procedureDetails?.tools,
                      )
                        .filter(([key, value]) => !isNull(value))
                        .map(([key, value], idx) => (
                          <ImplantInformationComponent
                            key={idx}
                            value={value}
                            title={
                              (!isUndefined(key) && procedureTools[key]) ||
                              "N/A"
                            }
                          />
                        ))}
                    </Box>
                  )}
                </Card>
              </Grid>
            )}

            {typeof surgery.procedureDetails?.right !== "undefined" && (
              <Grid
                item
                xs={12}
                md={
                  surgery?.procedureDetails?.left &&
                  surgery.procedureDetails?.right
                    ? 6
                    : 12
                }
                order={{ xs: 3, sm: 3, md: 2 }}
              >
                <Card>
                  <Box>
                    <Box mb={1}>
                      <Typography variant="h6" color="#801973">
                        {t("Procedure-Information.Title")}
                      </Typography>
                    </Box>
                    {Object.entries(surgery.procedureDetails?.right)
                      .filter(
                        ([key, value]) => key !== "tools" && !isNull(value),
                      )
                      .map(([key, value], idx) => (
                        <ImplantInformationComponent
                          key={idx}
                          value={formatValue(value, key)}
                          title={
                            (!isUndefined(key) && procedureProperties[key]) ||
                            "N/A"
                          }
                        />
                      ))}
                  </Box>
                  {typeof surgery.procedureDetails?.right?.tools !==
                    "undefined" && (
                    <Box mt={1}>
                      <Box mb={1}>
                        <Typography variant="h6" color="#801973">
                          {t("Procedure.Tools.Title")}
                        </Typography>
                      </Box>
                      {Object.entries(surgery.procedureDetails?.right?.tools)
                        .filter(([key, value]) => !isNull(value))
                        .map(([key, value], idx) => (
                          <ImplantInformationComponent
                            key={idx}
                            value={value}
                            title={
                              (!isUndefined(key) && procedureTools[key]) ||
                              "N/A"
                            }
                          />
                        ))}
                    </Box>
                  )}
                </Card>
              </Grid>
            )}

            {typeof surgery.devices?.implants?.left !== "undefined" && (
              <Grid
                item
                xs={12}
                md={
                  surgery.devices?.implants?.left &&
                  surgery.devices?.implants?.right
                    ? 6
                    : 12
                }
                order={{ xs: 2, sm: 2, md: 3 }}
              >
                <Card>
                  <Box mb={2}>
                    <Typography variant="h6" color="#801973">
                      {t("Implant-Registration.LeftImplant")}
                    </Typography>
                  </Box>
                  {Object.entries(
                    omit(surgery.devices?.implants?.left, "warranty"),
                  )
                    .filter(
                      ([key, value]) =>
                        !isNull(value) && !isUndefined(implantProperties[key]),
                    )
                    .map(([key, value], idx) => (
                      <ImplantInformationComponent
                        key={idx}
                        value={value}
                        title={
                          (!isUndefined(key) && implantProperties[key]) || "N/A"
                        }
                      />
                    ))}

                  {me.isDoctor && accountCountryUS && (
                    <Box display="flex" justifyContent="flex-end" mt={3}>
                      <Button
                        variant="contained"
                        disableElevation
                        sx={{
                          borderRadius: "20px",
                          backgroundColor: "#540d5a",
                          "&:hover": {
                            backgroundColor: "#6e0072",
                          },
                        }}
                        onClick={() => handleSetInformationExplant("left")}
                      >
                        {t("Device.Tracking.ExplantDevice")}
                      </Button>
                    </Box>
                  )}
                </Card>
              </Grid>
            )}

            {typeof surgery.devices?.implants?.right !== "undefined" && (
              <Grid
                item
                xs={12}
                md={
                  surgery.devices?.implants?.left &&
                  surgery.devices?.implants?.right
                    ? 6
                    : 12
                }
                order={{ xs: 4, sm: 4, md: 4 }}
              >
                <Card>
                  <Box mb={2}>
                    <Typography variant="h6" color="#801973">
                      {t("Implant-Registration.RightImplant")}
                    </Typography>
                  </Box>
                  {Object.entries(
                    omit(surgery.devices?.implants?.right, "warranty"),
                  )
                    .filter(
                      ([key, value]) =>
                        !isNull(value) && !isUndefined(implantProperties[key]),
                    )
                    .map(([key, value], idx) => (
                      <ImplantInformationComponent
                        key={idx}
                        value={value}
                        title={
                          (!isUndefined(key) && implantProperties[key]) || "N/A"
                        }
                      />
                    ))}
                  {me.isDoctor && accountCountryUS && (
                    <Box display="flex" justifyContent="flex-end" mt={3}>
                      <Button
                        variant="contained"
                        color="secondary"
                        disableElevation
                        sx={{
                          borderRadius: "20px",
                          backgroundColor: "#540d5a",
                          "&:hover": {
                            backgroundColor: "#6e0072",
                          },
                        }}
                        onClick={() => handleSetInformationExplant("right")}
                      >
                        {t("Device.Tracking.ExplantDevice")}
                      </Button>
                    </Box>
                  )}
                </Card>
              </Grid>
            )}

            {/*warranties*/}
            {typeof surgery.devices?.implants?.left !== "undefined" && (
              <Grid
                item
                xs={12}
                md={
                  surgery.devices?.implants?.left &&
                  surgery.devices?.implants?.right &&
                  (separateWarrantyCard(surgery) ? 6 : 12)
                }
                order={{
                  xs: separateWarrantyCard(surgery) ? 2 : 5,
                  sm: 2,
                  md: 5,
                }}
              >
                <Card>
                  <Box mb={2}>
                    <Typography variant="h6" color="#801973">
                      {t("Warranty.Information.Title")}
                    </Typography>
                  </Box>
                  <Box>
                    {renderWarrantyProgramName(surgery, "left")}

                    {surgery.devices?.implants?.left?.warranty?.startDate && (
                      <ImplantInformationComponent
                        value={formatValue(
                          surgery.devices?.implants?.left?.warranty?.startDate,
                          "startDate",
                        )}
                        title={warrantyProperties["startDate"] || "N/A"}
                      />
                    )}
                    {surgery.devices?.implants?.left?.warranty?.endDate && (
                      <ImplantInformationComponent
                        value={validUntil(surgery.devices?.implants?.left)}
                        title={warrantyProperties["endDate"] || "N/A"}
                      />
                    )}

                    {renderWarrantyEligibilityRow(surgery, "left")}
                  </Box>
                </Card>
              </Grid>
            )}

            {typeof surgery.devices?.implants?.right !== "undefined" &&
              separateWarrantyCard(surgery) && (
                <Grid
                  item
                  xs={12}
                  md={
                    surgery.devices?.implants?.left &&
                    surgery.devices?.implants?.right &&
                    (separateWarrantyCard(surgery) ? 6 : 12)
                  }
                  order={{ xs: 4, sm: 4, md: 6 }}
                >
                  <Card>
                    <Box mb={2}>
                      <Typography variant="h6" color="#801973">
                        {t("Warranty.Information.Title")}
                      </Typography>
                    </Box>
                    <Box>
                      {renderWarrantyProgramName(surgery, "right")}

                      {surgery.devices?.implants?.right?.warranty
                        ?.startDate && (
                        <ImplantInformationComponent
                          value={formatValue(
                            surgery.devices?.implants?.right?.warranty
                              ?.startDate,
                            "startDate",
                          )}
                          title={warrantyProperties["startDate"] || "N/A"}
                        />
                      )}
                      {surgery.devices?.implants?.right?.warranty?.endDate && (
                        <ImplantInformationComponent
                          value={validUntil(surgery.devices?.implants?.right)}
                          title={warrantyProperties["endDate"] || "N/A"}
                        />
                      )}

                      {renderWarrantyEligibilityRow(surgery, "right")}
                    </Box>
                  </Card>
                </Grid>
              )}
          </Grid>
        </Box>
      ))}

      {breastData?.meta?.total_pages && (
        <Box py={2} display="flex" justifyContent="center">
          <Pagination
            count={breastData?.meta?.total_pages}
            page={page}
            handlePagination={handlePagination}
          />
        </Box>
      )}
    </>
  );
};
